
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { Menu } from '@/types/version'

@Component({
  name: 'versionAdd'
})
export default class extends Vue {
  private title = '新增版本'
  private info = {
    versionName: '',
    versionDesc: '',
    menuList: [{}]
  }

  private rules ={
    versionName: [{ required: true, message: '请输入版本名称', trigger: ['blur', 'change'] }]
  }

  private menuList = []
  private props = {
    label: 'menuName',
    children: 'childNode',
    disabled: (data: Menu) => {
      // 设置公用菜单置灰
      if (data.menuCommon === '1') {
        return true
      }
    }
  }

  private checkedKeys = ['']
  created () {
    if (this.$route.params.id) {
      this.title = '编辑版本'
      this.detailsGet()
    } else {
      this.menuGet()
    }
  }

  private submitShow = false

  // 详情
  detailsGet () {
    this.$axios.get(this.$apis.version.versionDetails, {
      versionId: this.$route.params.id
    })
      .then((res) => {
        this.info = {
          versionName: res.versionName || '',
          versionDesc: res.versionDesc || '',
          menuList: [{}]
        }
        this.menuGet()
      })
  }

  // 菜单数据
  menuGet () {
    this.$axios.get(this.$apis.version.menuList, {
      versionId: this.$route.params.id || null
    })
      .then((res) => {
        this.menuList = res || []
        this.checkedKeysSet(this.menuList)
      })
  }

  // 处理勾选数据
  checkedKeysSet (val: never[]) {
    val.map((item: Menu) => {
      if (item.childNode) {
        this.checkedKeysSet(item.childNode)
      } else {
        if (item.isSelect === '1') {
          this.checkedKeys.push(item.menuId)
        }
      }
    })
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.info.menuList = []
        // 获取已勾选的菜单
        const checkList = (this.$refs.tree as HTMLFormElement).getCheckedNodes(false, true)
        checkList.map((item: Menu) => {
          this.info.menuList.push({ menuId: item.menuId })
        })
        const url = this.$route.params.id
          ? this.$apis.version.versionUpdate
          : this.$apis.version.versionAdd
        this.$axios.post(url, {
          versionId: this.$route.params.id || null,
          ...this.info
        })
          .then(() => {
            this.submitShow = false
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$router.push({ name: 'version' })
          })
          .catch(err => {
            this.submitShow = false
            this.$message.error(err)
          })
      }
    })
  }
}
